import { Component, OnInit } from '@angular/core';
import { CommunicationService } from 'src/app/services/communication.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Nav, WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-action-buttons',
  templateUrl: './action-buttons.component.html',
  styleUrls: ['./action-buttons.component.scss']
})
export class ActionButtonsComponent implements OnInit {
  public component = this;

  public nav: Nav;

  constructor(
    public userService: UserService,
    public communicationService: CommunicationService
  ) { }


  ngOnInit(): void {
    this.initNav();
    setTimeout(() => {
      this.communicationService.setSidebarContainer({
        name: 'actionCenter',
        height: 100,
        isMinimized: false,
        isVisible: this.communicationService.user.config.actionCenter.visible
      });
    });
  }

  initNav() {
    this.nav = {
      menu: [
        {
          className: 'lg xfw3-bg-secondary round icon',
          "accessLevel": [
            "admin"
          ],
          icon: 'fa-regular fa-comment-plus',
          noToggle: true,
          text: '',
          info: '',
          outlet: 'window',
          path: 'xfw/nl/window/ticket'
        },
        {
          className: 'lg xfw3-bg-secondary round icon',
          "accessLevel": [
            "admin"
          ],
          icon: 'fa-regular fa-list-check',
          noToggle: true,
          text: '',
          info: '',
          outlet: 'window',
          path: 'xfw/nl/window/taak'
        },
        {
          className: 'lg xfw3-bg-secondary round icon',
          "accessLevel": [
            "admin"
          ],
          icon: 'fa-regular fa-messages',
          noToggle: false,
          isSelected: this.communicationService.user?.config?.actionCenter?.visible,
          text: '',
          info: 'ActionCenter',
          func: 'showActionCenter',
        },
        {
          className: 'lg xfw3-bg-secondary round icon',
          icon: 'fa-regular fa-arrow-up',
          noToggle: true,
          text: '',
          info: 'ToTop',
          func: 'scrollToTop',
        }
      ],
      navId: -5
    };
  }

  showActionCenter(event) {
    !Array.isArray(event) && event && (this.nav.menu[2].isSelected = true);

    this.communicationService.user.config.actionCenter.visible = this.nav.menu[2].isSelected;
    this.userService.setUser({ config: { actionCenter: {visible: this.communicationService.user.config.actionCenter.visible }}}, true);

    this.communicationService.setSidebarContainer({
      name: 'actionCenter',
      height: 100,
      isMinimized: false,
      isVisible: this.communicationService.user.config.actionCenter.visible
    });
  }

  scrollToTop() {
    window.scroll(0,0);
  }
}
