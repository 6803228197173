import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DataGroup } from 'src/app/data';
import { DataGenericComponent } from 'src/app/widget/data-generic/data-generic.component';
import { WidgetEvent, WidgetState } from 'src/app/widgets';

@Component({
  selector: 'app-action-section',
  templateUrl: './action-section.component.html',
  styleUrls: ['./action-section.component.scss']
})
export class ActionSectionComponent extends DataGenericComponent implements OnInit, OnDestroy {
  @Input() widgetId: string;
  @Input() dataGroupItem: DataGroup;

  @Output() eventSelect: EventEmitter<any> = new EventEmitter();

  public title: string = '';
  public rowVisible: number = -1;

  public isItemSelected: boolean = false;

  public sectionState: SectionState = {
    read: 0,
    unread: 0,
    urgent: 0
  }

  private selectItem = {
    show: false,
    item: null,
    index: null
  };

  ngOnInit(): void {
    this.setFromParams(this.dataGroupItem, this.dataItem, this.widgetId);

    this.setQueryParams(this.communicationService.queryParams);
    this.initWidget();

    this.communicationService.initWidget({
      widgetId: this.widgetId,
      component: this,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.CREATE,
          func: 'createAction'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.OPEN,
          func: 'openAction'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.REFRESHED,
          func: 'selectAction'
        },
        {
          widgetGroup: [this.widgetId],
          event: WidgetEvent.REFRESHED,
          func: 'checkStatus'
        }
      ]
    });
  }

  ngOnDestroy(): void {
    this.communicationService.destroyWidgets([this.widgetId]);
    super.ngOnDestroy();
  }

  onSelectItem(item: any, index) {

    if (!item.isSelected) {

      this.dataGroupItem.children.forEach(child => {
        child.paramsFrom.forEach(paramFrom => {
          child.params.find(param => param.key === paramFrom.key).val = item[paramFrom.key];
        });
      });

      this.userService.setUser({
        config: {
          actionCenter: {
            itemSelected: { objectId: item['objectId'] },
            unread: this.communicationService.user.config.actionCenter.unread.filter(value => value !== item['objectId'])
          }
        }
      }, true);

      this.dataGroupItem.dataTable[0].data.forEach(row => {
        row.isSelected = false;
      });

      this.dataGroupItem.isSelected = true;
      this.isItemSelected = true;
      item.isSelected = true;
      this.rowVisible = index;
    }
  }

  onDeselectItem() {
    this.dataGroupItem.isSelected = false;
    this.dataGroupItem.dataTable[0].data.forEach(row => {
      row.isSelected = false;
    });

    this.isItemSelected = false;
    this.rowVisible = -1;

    this.userService.setUser({
      config: {
        actionCenter: {
          itemSelected: { objectId: null }
        }
      }
    }, true);
  }

  onUpdateMutation(dataItem) {
    this.updateMutations().then((data: any[]) => {
      this.forceRefresh(dataItem);
    });
  }

  forceRefresh(dataItem) {
    this.communicationService.performAction({
      info: 'action item ',
      widgetGroup: ['action'],
      event: WidgetEvent.REFRESH,
      dataItem: { sectionId: +dataItem.sectionJSON.sectionId }
    });
  }

  createAction(params) {
    this.updateMutations({
      mutations: [{...params.dataItem, ...{ crud: 'create' }}],
      forceReload: true
    }).then((data: any[]) => {
      console.log('actionData', data);

      // this.onSelect(this.dataGroupItem);
      // this.selectItem = {
      //   show: true,
      //   item: data[data.length - 1],
      //   index: data.length - 1
      // };
    });
  }

  openAction(params) {
    // this.onSelect(this.dataGroupItem);

    this.selectItem = {
      show: true,
      item: this.dataGroupItem.dataTable[0].data.find(row => row['objectId'] === params.dataItem['objectId']),
      index: this.dataGroupItem.dataTable[0].data.findIndex(row => row['objectId'] === params.dataItem['objectId']),
    };

    // hier moeten we zelf de WidgetEvent.REFRESHED forceren

    this.communicationService.performAction({
      widgetGroup: [this.widgetId],
      event: WidgetEvent.REFRESHED
    });
  }

  selectAction() {
    if (this.communicationService.user.config.actionCenter?.itemSelected?.objectId) {
      this.dataGroupItem.dataTable[0].data.forEach((item, index) => {
        (item['objectId'] === this.communicationService.user.config.actionCenter.itemSelected.objectId) &&
          this.onSelectItem(item, index);
      });
    }

    if (this.selectItem.show) {
      this.selectItem.show = false;
      this.onSelectItem(this.selectItem.item, this.selectItem.index);
    }
  }
}

interface SectionState {
  read: number,
  unread: number,
  urgent: number
}
