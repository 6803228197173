import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DataGenericComponent } from '../data-generic/data-generic.component';
import { WidgetState, WidgetEvent, Param, Nav, EventParams } from 'src/app/widgets';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subscription, fromEvent } from 'rxjs';
import { DataGroup } from 'src/app/data';
import { Msg } from 'src/app/services/notification.service';

@Component({
  selector: 'app-data-cards-group',
  templateUrl: './data-cards-group.component.html',
  styleUrls: ['./data-cards-group.component.scss']
})
export class DataCardsGroupComponent extends DataGenericComponent implements OnInit, OnDestroy {
  public groupEdit = { mode: false };
  public groupIndex: number = null;

  public layout = {
    dataListHeight: 0,
    dataRowHeight: 0,
  };

  public tempRowIndex: number = -1;
  public currentGroupItem: any;
  public editGroupItem: any;
  public currentItem: any;

  public addRowNav: Nav;

  @ViewChild('dataList', { static: false }) dataList: ElementRef | undefined;
  @ViewChild('gridFooter', { static: false }) gridFooter: ElementRef | undefined;

  ctrlKeyPressed = false;
  dragging = false;
  keydownSubscription: Subscription;
  keyupSubscription: Subscription;

  @HostListener('window:resize')
  onResize() {
    if (this.dataItems && this.gridFooter?.nativeElement && this.dataList?.nativeElement) {
      const dataItems = this.dataItems.toArray();
      const cols = [... new Set(dataItems.map(dataItem => dataItem.nativeElement.getBoundingClientRect().left))].length;
      const rows = dataItems.filter(dataItem => dataItem.nativeElement.getBoundingClientRect().left === dataItems[0].nativeElement.getBoundingClientRect().left).length;
      const height = (cols && rows > 1) ? dataItems[cols].nativeElement.getBoundingClientRect().top - dataItems[0].nativeElement.getBoundingClientRect().top : 0;

      const dataListHeight = this.gridFooter?.nativeElement.getBoundingClientRect().top - this.dataList?.nativeElement.getBoundingClientRect().top;

      if (dataListHeight !== this.layout.dataListHeight && this.dataGroupItem.page !== -1) {
       this.layout.dataListHeight = dataListHeight;

        if (this.layout.dataRowHeight === 0 && height) {
          this.layout.dataRowHeight = height;
        }

        if (this.layout.dataListHeight && this.layout.dataRowHeight) {
          this.dataGroupItemInstance.page = Math.floor(this.layout.dataListHeight / this.layout.dataRowHeight ?? 40) * cols;
          this.setCurrentPage();
        }
      }
    }
  }

  initResize() {
    this.layout.dataListHeight = 0;
    this.layout.dataRowHeight = 0;

    setTimeout(() => {
      this.onResize();
    });
  }

  ngOnInit(): void {
    super.ngOnInit();

    !this.communicationService.widget.find(widget => widget.widgetId === this.widgetId)
      ?.subscribeTo.some(subscribeTo => subscribeTo.func === 'initResize') &&
      this.communicationService.initWidget({
        widgetId: this.widgetId,
        component: this.component,
        state: WidgetState.OK,
        subscribeTo: [
          {
            event: WidgetEvent.REFRESHED,
            func: 'initResize',
          }
        ]
      });

    this.keydownSubscription = fromEvent(window, 'keydown').subscribe((event: KeyboardEvent) => {
      if (event.key === 'Control') {
        this.ctrlKeyPressed = true;
      }
    });

    this.keyupSubscription = fromEvent(window, 'keyup').subscribe((event: KeyboardEvent) => {
      if (event.key === 'Control') {
        this.ctrlKeyPressed = false;
      }
    });

    this.initNav();

    if (this.dataGroupItemInstance.rowOptions?.nav?.menu) {
      this.nav['menu'] = this.dataGroupItemInstance.children[0].rowOptions.nav.menu;
    }

    this.dataGroupItemInstance.children.length && this.initDataGroupItemInstanceForGroup(this.dataGroupItemInstance);

    const isLoaded = this.loaded$.subscribe(loaded => {
      if (loaded) {
        this.initSelectedChild(this.dataGroupItemInstance);
        isLoaded.unsubscribe();
      }
    });

  }

  cancel(params, dataItem?) {
    dataItem.item.crud = 'delete';
    this.editGroupItem = dataItem.groupItem;
    this.editGroupItem.crud = 'updateGroup';

    this.updateMutations().then((data) => {
      this.removeEmptyGroupRows();
    });
  }

  removeEmptyGroupRows() {
    this.dataGroupItemInstance.dataTable[0].data = this.dataGroupItemInstance.dataTable[0].data.filter(row => row.group.length);
  }

  initNav() {
    this.addRowNav = {
      navId: 1,
      primary: {
        icon: 'fa-solid fa-plus',
        text: '',
        className: "icon xfw3-bg-secondary round xs",
        type: '',
        info: 'Toevoegen',
        func: 'addNewRow',
        params: [],
        disabled: false,
        isInvisible: !(this.dataGroupItemInstance.edit.create || this.dataGroupItemInstance.edit.createWhenListIsEmpty)
      }
    }
  }

  addNewRow(params: Param[], dataItem?, data?) {
    this.addRow({
      params,
      dataItem,
      data
    });
  }

  addGroupRow(eventParams: EventParams) {
    const dataItem = structuredClone(this.dataGroupItemInstance.children[0].dataTable[0].data[0]);

    Object.keys(dataItem).forEach((key) => {
      !this.dataGroupItemInstance.children[0].dataTable[0].foreignKeys
        .some(foreignKeys => foreignKeys.key === key) && (dataItem[key] = null);
    });

    this.dataGroupItemInstance.children[0].dataTable[0].fields.forEach(fields => {
      fields.fieldGroup.forEach(field => {
        field.xfw?.inputData?.defaultItem ?
          (dataItem[field.key] = field.xfw.inputData.defaultItem)
          :
          field.key.includes('fileUid') && (dataItem[field.key] = Date.now().toString());
      });
    });

    dataItem.crud = 'create';

    this.dataGroupItemInstance.children[0].dataTable[0].data.push(dataItem);
    this.groupIndex++;
  }

  selectParent(dataGroupItemInstance: DataGroup, groupItem: any): void {
    this.currentGroupItem !== groupItem && dataGroupItemInstance.children[0].selectionMode !== 'none' && this.selectChild(dataGroupItemInstance.children[0], null, groupItem.group[0]);
    this.currentGroupItem = groupItem;
    this.select({ dataItem: groupItem });
  }

  selectChild(dataGroupItemInstance: DataGroup, groupItem: any, item: any): boolean {
    this.currentItem && (this.currentItem.isSelected = false);
    item.isSelected = true;
    this.currentItem = item;

    const findParams: Param[] = [];

    dataGroupItemInstance.params.forEach(param => {
      item[param.key] && findParams.push({
        key: param.key,
        val: item[param.key],
        isQueryParam: param.isQueryParam
      });
    });

    this.communicationService.mergeQueryParams(this.widgetId, findParams, [], null, true);

    groupItem && this.currentGroupItem !== groupItem && (this.currentGroupItem = groupItem) && this.select({ dataItem: groupItem });

    return true;
  }

  initSelectedChild(dataGroupItemInstance: DataGroup) {
    let selectedParam: Param = null;

    dataGroupItemInstance.children[0].paramsFrom.forEach(paramFrom => {
      this.communicationService.queryParams.some(param => param.key === paramFrom.key) &&
        (
          selectedParam = {
            key: paramFrom.key,
            val: +this.communicationService.queryParams.find(param => param.key === paramFrom.key).val,
            isQueryParam: true
          }
        );
    });

    selectedParam ? this.dataGroupItemInstance.dataTable[0].data.forEach(row => {
      row.group.forEach(item => {
          item[selectedParam.key] === selectedParam.val && (item.isSelected = true);
          item[selectedParam.key] === selectedParam.val && (this.currentItem = item);
        });
      })
      : dataGroupItemInstance.children[0].selectionMode !== 'none' &&
          dataGroupItemInstance.dataTable[0]?.data[0]?.group[0] &&
          this.selectChild(dataGroupItemInstance.children[0], null, dataGroupItemInstance.dataTable[0].data[0].group[0]);
  }

  ngOnDestroy() {
    this.keydownSubscription.unsubscribe();
    this.keyupSubscription.unsubscribe();
    super.ngOnDestroy();
  }

  close(event) {
    if (this.dataGroupItemInstance.children[0].dataTable) {
      this.dataGroupItemInstance.children[0].dataTable[0].data.forEach(row => row.edit = false);
      this.groupEdit.mode = false;
    }
  }

  initDataGroupItemInstanceForGroup(dataGroupItemInstance: DataGroup) {
    this.communicationService.initWidget({
      widgetId: dataGroupItemInstance.children[0].widgetId,
      component: this.component,
      state: WidgetState.OK,
      subscribeTo: [
        {
          widgetGroup: [dataGroupItemInstance.children[0].widgetId, dataGroupItemInstance.children[0].widgetId + '_form'],
          event: WidgetEvent.SAVE,
          promise: true,
          func: 'updateGroupMutations'
        },
        {
          widgetGroup: [dataGroupItemInstance.children[0].widgetId + '_form'],
          event: WidgetEvent.CLOSE,
          func: 'close'
        },
      ]
    });

    this.dataService.getDataTable(dataGroupItemInstance.children[0], dataGroupItemInstance.children[0].db, dataGroupItemInstance.children[0].src[0].name, []).subscribe(data => {
      dataGroupItemInstance.children[0].dataTable = data;
    });
  }

  updateGroupMutations(eventParams: EventParams) {
    this.editGroupItem.crud = 'updateGroup';
    this.editGroupItem.group = this.dataGroupItemInstance.children[0].dataTable[0].data;
    return this.updateMutations({
        mutations: null,
        forceReload: true
      }
    );
  }

  copy(params: Param[], dataItem?, data?) {
    this.dataGroupItemInstance.dataTable[0].data[dataItem.rowIndex].crud = 'copyGroup';
    this.dataGroupItemInstance.dataTable[0].data[dataItem.rowIndex].group[dataItem.groupIndex].crud = 'copy';

    return this.updateMutations({
      mutations: null,
      forceReload: true
    });
  }

  setDataGroupItemInstanceForGroup(rowIndex: number) {
    this.dataGroupItemInstance.children[0].dataTable[0].data = this.dataGroupItemInstance.dataTable[0].data[rowIndex].group ?? [];
  }

  setGroupEditMode(params: Param[], dataItem?, data?) {
    this.setDataGroupItemInstanceForGroup(dataItem.rowIndex);
    this.editGroupItem = dataItem.groupItem;

    this.dataGroupItemInstance.children[0].dataTable[0].data[dataItem.groupIndex].isSelected = true;
    this.dataGroupItemInstance.children[0].dataTable[0].data[dataItem.groupIndex].edit = true;

    this.groupEdit.mode = true;
    this.groupIndex = dataItem.groupIndex;
  }

  dropGroup(event: CdkDragDrop<any[]>) {
    event.previousContainer === event.container ?
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex)
      :
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );

    this.setSortOrder();

    this.dataGroupItemInstance.dataTable[0].data.forEach((row, rowIndex) => {
      row.crud = 'dragDrop';
    });

    this.updateMutations();
  }

  dropCard(event: CdkDragDrop<any[]>) {
    const msg: Msg = {
      msgType: "warning",
      msg: {
        title: "Optie niet toegestaan",
        body: {
          text: "Er is al een optie geselecteerd die hoort bij de vragen.",
        }
      }
    };

    const prevContainerIndex = event.previousContainer.id.split('_')[1];
    const containerIndex = event.container.id.split('_')[1];

    let dropAllowed = true;

    this.dataGroupItemInstance.children[0].dataTable[0]?.primaryKeys &&
      this.dataGroupItemInstance.children[0].dataTable[0].primaryKeys.forEach(primaryKey => {
        event.container.data.some(row => row[primaryKey.key] === event.previousContainer.data[event.previousIndex][primaryKey.key]) &&
        prevContainerIndex !== containerIndex &&
          (dropAllowed = false);
      });

    if (!dropAllowed) {
      this.notificationService.addNotification({
        msgType: msg.msgType,
        msg: {
          title: msg.msg.title,
          body: { text: msg.msg.body.text }
        }
      });

      return;
    }

    const copyArrayItem = (srcArray: any[], destArray: any[], srcIndex: number, destIndex: number): void => {
      destArray.splice(destIndex, 0, structuredClone(srcArray[srcIndex]));
    };

    (event.previousContainer === event.container) ?
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex) :
      (this.ctrlKeyPressed ?
        (() => {
          this.tempRowIndex !== -1 &&
            (this.dataGroupItemInstance.dataTable[0].data[this.tempRowIndex].group = this.dataGroupItemInstance.dataTable[0].data[this.tempRowIndex].group.filter(row => !row.temp));

          copyArrayItem(
            event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex,
          )
        })()
        :
        transferArrayItem(
          event.previousContainer.data,
          event.container.data,
          event.previousIndex,
          event.currentIndex,
        )
      );

    this.setSortOrder();

    !this.ctrlKeyPressed && (this.dataGroupItemInstance.dataTable[0].data[prevContainerIndex].crud = 'delete');

    this.dataGroupItemInstance.dataTable[0].data[containerIndex].crud = 'dragDropGroup';

    this.dataGroupItemInstance.dataTable[0].data[containerIndex].group.forEach((row) => {
      this.dataGroupItemInstance.children[0].dataTable[0]?.foreignKeys &&
        this.dataGroupItemInstance.children[0].dataTable[0].foreignKeys.forEach(foreignKey => {
          row[foreignKey.key] = this.dataGroupItemInstance.dataTable[0].data[containerIndex][foreignKey.key];
        });
    });

    this.updateMutations().then(() => {
      this.removeEmptyGroupRows();
    });
  }

  setSortOrder() {
    let sortOrder = 0;

    this.dataGroupItemInstance.dataTable[0].data.forEach((row) => {
      row.group.forEach((groupRow) => {
        groupRow.sortOrder = sortOrder++;
      });

      row.group.length && (row.sortOrder = row.group[0].sortOrder);
    });
  }

  exitedCard(event: CdkDragDrop<any[]>, rowIndex: number) {
    if (!this.dragging && this.ctrlKeyPressed) {
      this.dragging = true;

      const currentIdx = event.container.data.findIndex(
        (find) => find.groupId === event.item.data.groupId
      );

      this.tempRowIndex = rowIndex;

      this.dataGroupItemInstance.dataTable[0].data[rowIndex].group.splice(currentIdx + 1, 0, {
        ...event.item.data,
        temp: true,
      });
    }
  }

}
