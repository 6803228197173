@if (loaded) {
  <div class="xfw3-page-container">
    <div class="xfw3-page xfw3-grid-container"
        divBreakpointClass
        (breakpointClass)="checkSizes($event)">

    <div class="main">
      <app-main-menu/>
    </div>

    @if (headerBlocks.length) {
      <div class="sub-menu xfw3-grid-col-container xfw3-px">
        <div class="g-col-12">
          <app-content-block
            [blocks]="headerBlocks"
            [carousel]="carousel"
            [environmentJSON]="environmentJSON"
            [meta]="{ hideSearch: environmentJSON.hideSearch, hideLanguage: environmentJSON.hideLanguage, searchUrl: searchUrl, carouselData: carouselData }">
          </app-content-block>
        </div>
      </div>
    }
    <div class="content">
      <div class="content-body">
        @if (blocks) {
          <app-content-block
          [blocks]="blocks"
          [environmentJSON]="environmentJSON"/>
        }
        @if (footerBlocks) {
          <app-content-block
            [blocks]="footerBlocks"
            [environmentJSON]="environmentJSON"/>
        }
      </div>
    </div>
    </div>
  </div>

  <app-notification></app-notification>
  <app-action-buttons></app-action-buttons>
  <app-debug-info/>
}


