import {
  AfterViewInit, Component, ElementRef, HostListener, Input,
  OnDestroy, OnInit, Renderer2, ViewChild
} from '@angular/core';
import {DataService} from '../../services/data.service';
import {Subscription} from 'rxjs';
import {Nav, NavCurrent, NavItem, WidgetEvent, WidgetState} from '../../widgets';
import {ActivatedRoute, Router} from '@angular/router';
import { CommunicationService } from 'src/app/services/communication.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { SEOService } from 'src/app/seo.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ContentService } from 'src/app/services/content.service';
import { CustomFuncService } from 'src/app/services/custom-func.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tabstrip',
  templateUrl: './tabstrip.component.html',
  styleUrls: ['./tabstrip.component.css']
})
export class TabstripComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() widgetId: string;
  @Input() src: string;
  @Input() nav: Nav;
  @Input() component: any;
  @Input() dataItem: any;

  public loaded = false;
  public hamburger: boolean = false;
  public showHamburgerMenu: boolean = false;

  public msg: any;
  public isSlideShow: boolean = false;

  public tabstripWidth: {
    current: number,
    nextMax: number,
    min: number
  }

  public maintenance: Nav = {
    navId: -120,
    menu: [{
      icon: 'dripicons dripicons-gear',
      text: '',
      info: 'Aanpassen',
      func: 'tablist.edit',
    }]
  };

  public tabs: any[] = [];

  public init = false;

  public routeSubscription: Subscription;
  public userSubscription: Subscription;
  public queryParamsSubscription: Subscription;

  public current: NavCurrent = {
    url: null,
    navItemId: null
  }

  @ViewChild('tabstrip', { static: false }) tabStrip: ElementRef;

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private seoService: SEOService,
    public communicationService: CommunicationService,
    private activatedRoute: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    public dataService: DataService,
    public utilsService: UtilsService,
    public contentService: ContentService,
    private renderer: Renderer2,
    private elementRef: ElementRef,
    public customFuncService: CustomFuncService) {
  }

  routeParamChanged(params) {
    // console.log('route nav', this.widgetId, params);
    this.setSelected(null, false);
  }

  queryParamChanged(params) {
    // console.log('query nav', params);
  }

  userInfoChanged(params) {
    // console.log('user nav', params)
  }

  onClickOutside(e: Event) {
    this.showHamburgerMenu = false;
  }

  checkSizes(event) {

    // const padding: number = 30;

    // if (this.tabstripWidth && event?.newRect?.width !== event?.oldRect?.width) {
    //   let newWidth = event.newRect.width - padding;

    //   if ((newWidth <= this.tabstripWidth.current || newWidth >= this.tabstripWidth.nextMax || (this.hamburger && newWidth >= this.tabstripWidth.min))) {
    //     this.rearrangeTabstrip();
    //   }
    // }
  }

  ngOnInit(): void {
    if (this.widgetId) {
      this.communicationService.initWidget({
        widgetId: this.widgetId + '_tabstrip',
        component: this,
        state: WidgetState.OK,
        subscribeTo: [
          {
            widgetGroup: [this.widgetId + '_tabstrip'],
            event: WidgetEvent.SLIDESHOW,
            func: 'slideShow'
          },
          {
            event: WidgetEvent.QUERYPARAMCHANGED,
            func: 'queryParamChanged'
          },
          {
            event: WidgetEvent.ROUTEPARAMCHANGED,
            func: 'routeParamChanged'
          },
        ]
      });
    }
  }

  ngAfterViewInit(): void {
    if (!this.nav) {
      this.loadNav();
    } else {
      this.processNav(this.nav);
    }
  }

  setSelected(navItemId, doSelect:boolean = true) {
    if (this.loaded) {
      if (navItemId !== 0 && !navItemId) {
        let activatedRoute: any = this.activatedRoute;
        let url = activatedRoute._routerState.snapshot.url;

        let route = url.split('?')[0]
        let queryParams = activatedRoute.queryParams._value;

        this.tabs.forEach(tab => {
          let check;

          if (tab.params && tab.params.length) {
          const tabParams = this.utilsService.objectFromParams(tab.params.filter(param => param.isQueryParam && param.val));

          check = (Object.keys(queryParams).length > 0 && Object.keys(tabParams).length > 0) ?
                      this.utilsService.isSubset(queryParams, tabParams) :
                      true;
          } else {
            check = true;
          }

          if (tab.path && tab.path === route.substring(1, route.length) && check) {
            navItemId = tab.navItemId;
          }

          if (tab.menu) {
            tab.menu.forEach(item => {
              if (item.path && item.path === route.substring(1, route.length) && check) {
                navItemId = item.navItemId;
              }
            });
          }
        });

        if (!navItemId) {
          navItemId = this.nav.tabGroup[0].menu[0].navItemId;
        }
      }

      if (navItemId && navItemId !== this.current.navItemId) {
        this.tabs.forEach(tab => tab.isSelected = tab.noToggle ? false : tab.isSelected);
        this.current.navItemId = navItemId;

        this.nav.tabGroup.forEach(tabGroup => {
          tabGroup.isSelected = false;

          tabGroup.menu.forEach(tab => {
            tab.isSelected = tab.noToggle ? false : tab.isSelected;
          });

          if (navItemId) {
            const found = tabGroup.menu.find(x => x.navItemId === navItemId);

            if (found) {
              found.isSelected = true;
              tabGroup.isSelected = true;
              if (doSelect) {
                this.select(found);
              }

              if (this.tabs.filter(tab => tab.menu && tab.menu.length).some(x => x.groupId === tabGroup.groupId)) {
                this.tabs.filter(tab => tab.menu && tab.menu.length).find(x => x.groupId === tabGroup.groupId).isSelected = tabGroup.isSelected;
              }
            }
          }
        });
      }
    }
  }

  select(tab: NavItem, content?) {
    const url: any = [tab.path];
    let queryParams = this.communicationService.queryParams;

    this.showHamburgerMenu = false;

    if (tab.navItemId && (!tab.func || tab.func === 'selectDataGroupItem')) {
      this.setSelected(tab.navItemId, false);
    }

    if (tab.track) {
      this.googleAnalyticsService.sendAnalyticsEvent(tab.track);
    }

    // console.log(tab);

    if (tab.path && !tab.outlet) {
      if (tab.path.startsWith('http')) {
        window.open(tab.path, '_blank');
      }

      let fragment = url[0].split('#')[1];
      url[0]=url[0].split('#')[0];

      url.push({outlets: { window: null } });

      let params = [];

      if (queryParams.length) {
        tab.params.forEach(param => {
          if (param.isQueryParam) {
            params.push(queryParams.find(queryParam => queryParam.key === param.key));
          }
        });
      }
        //this.communicationService.mergeQueryParams(this.widgetId, queryParams, url, fragment);

      // console.log('params', params, url, tab);

      this.communicationService.setQueryParams(this.widgetId, params, url, fragment);

    } else if (tab.path && tab.outlet) {
        const outlets = {};
        outlets[tab.outlet] = [tab.path].join('/').split('/');

        this.communicationService.mergeQueryParams(this.widgetId, tab.params, [{ outlets: outlets }]);
    } else {
      if (tab.func) {
        if (tab.msg && tab.msg?.action !== 0) {
          this.msg = tab.msg;
          this.modalService.open(content, { centered: true }).result.then((result) => {
            if (result === 'OK') {
              this.customFuncService.perform(tab.func, tab.procedure, tab.params, tab.paramsFrom, {...tab.dataItem, ...this.dataItem}, this.component);

              if (tab.type !== 'button') {
                this.communicationService.mergeQueryParams(this.widgetId, tab.params);
              }
            }
          });
        } else {
          if (!tab.noToggle) {
            tab.isSelected = !tab.isSelected;
          }

          this.customFuncService.perform(tab.func, tab.procedure, tab.params, tab.paramsFrom, {...tab.dataItem, ...this.dataItem}, this.component);
          if (tab.type !== 'button') {
            this.communicationService.mergeQueryParams(this.widgetId, tab.params,[], null, true);
          }
        }
      }
    }
  }

  loadNav() {
    return new Promise((resolve, reject) => {
      this.dataService.getNav(this.src)
        .then(data => {
          // console.log('data: ' + data)
          this.nav = data;
          this.processNav(this.nav);
          resolve(null);
        })
        .catch(error => {
          console.log('error: ' + error)
          reject(error)
        });
      });
  }

  processNav(nav: Nav) {
    this.nav = this.contentService.changeLanguage(this.nav, this.communicationService.user.language);

    const testNav = structuredClone(this.nav);

    this.utilsService.toTabstrip2(testNav);


    /**
     * Promise is necessary, we have to wait until all tabs and groups
     * are measured. After that the tabs could be determined and
     * the tabstrip could be rendered.
     */
    const tabElementWidthPromise = [];

    this.nav.tabGroup.forEach((tabGroup) => {
      tabGroup.group = this.utilsService.parseTemplate(tabGroup.group, this.communicationService.user);

      tabElementWidthPromise.push(this.getTabElementWidth(tabGroup, tabGroup.group, 'xfw-tab'));
      tabGroup.isSelected = false;
      tabGroup.menuExpanded = false;

      tabGroup.menu.forEach((tab) => {
        tab.text = this.utilsService.parseTemplate(tab.text, this.communicationService.user);

        if (tab.isVisible) {
          tabElementWidthPromise.push(this.getTabElementWidth(tab, tab.text, 'xfw-tab'));
          tab.isSelected = false;
          tab.divide = false;

          if (tab.func && tab.type !== 'button') {
            tab.params.push({
              key: this.widgetId, //'nav-' + this.nav.navId,
              val: tab.navItemId,
              isQueryParam: true
            });
          }
        }

        if (tab.isSelectedVariableReference) {
          const result = this.utilsService.parseVariableToReference(tab.isSelectedVariableReference, this.communicationService.user);
          tab.isSelected = result.reference[tab.isSelectedVariableReference.split('.').pop()];
        }
      });
    });

    Promise.all(tabElementWidthPromise).then( values => {
      this.init = true;

      this.nav.tabGroup.forEach(tabGroup => {
        tabGroup.groupWidth = tabGroup.menu.reduce((prev, cur) => prev + (cur.width ? cur.width : 0), 0);
        tabGroup.showTabs = false;
      });

      this.rearrangeTabstrip().then(() => {
        this.loaded = true;
        let navItemId: number = null;

        if (this.communicationService.queryParams?.length) {
          navItemId = parseInt(this.communicationService.queryParams.find(queryParam => queryParam.key === this.widgetId)?.val) || this.nav.tabGroup[0].menu[0].navItemId;
        }

        this.setSelected(navItemId, this.nav.dataGroup ? true : false);
      });
    });
  }

  ngOnDestroy(): void {
    this.communicationService.destroyWidgets([this.widgetId + '_tabstrip']);
  }

  rearrangeTabstrip() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let floatRight = this.nav.floatRight;
        this.tabs.length = 0;

        if (this.tabStrip) {
          const padding: number = 30;
          const widthOfDropDownIcon = 24;

          let maxWidth =  this.tabStrip.nativeElement.offsetWidth;
          let maxWidthCheck = maxWidth - padding;

          this.hamburger = false;

          this.tabstripWidth = {
            current: 0,
            nextMax: 0,
            min: this.nav.tabGroup.reduce((acc, val) => acc + val.width + (val.menu.length > 1 ? widthOfDropDownIcon : 0), 0)
          }

          maxWidth = maxWidthCheck - this.tabstripWidth.min;

          this.nav.tabGroup.map(item => {
            return {
              group: item.group,
              icon: item.icon,
              className: item.className,
              floatLeft: item.floatLeft,
              accessLevel: item.accessLevel,
              forceGrouping: item.forceGrouping,
              groupWidth: item.groupWidth,
              width: item.width,
              showTabs: false,
              menu: item.menu
            };
          }).sort((left, right) => left.groupWidth - right.groupWidth).map(x => {
            return { diff: x.groupWidth - x.width, group: x.group, showTabs: x.showTabs, forceGrouping: x.forceGrouping  };
          }).map(x => {
            if (x.diff <= maxWidth) {
              if (!x.forceGrouping) {
                x.showTabs = true;
              }
              maxWidth -= x.diff;
            }
            return x;
          }).forEach(tab => {
            this.nav.tabGroup.find(x => x.group === tab.group).showTabs = tab.showTabs;
          });

          this.nav.tabGroup.forEach(tabGroup => {
            if (tabGroup.showTabs) {
              this.tabstripWidth.current += tabGroup.groupWidth;

              tabGroup.menu.forEach(tab => {
                if (tab.isVisible) {
                  this.tabs.push(tab);
                }
              });
            } else {
              this.tabstripWidth.current += tabGroup.width + (tabGroup.menu.length > 1 ? widthOfDropDownIcon : 0);

              this.tabs.push({
                groupId: tabGroup.groupId,
                icon: tabGroup.icon,
                className: tabGroup.className,
                text: tabGroup.group,
                menu: tabGroup.menu,
                floatLeft: tabGroup.floatLeft,
                isSelected: tabGroup.isSelected,
                accessLevel: tabGroup.accessLevel,
                menuExpanded: false
              });
            }
          });

          if (maxWidthCheck < this.tabstripWidth.min) {
            // hamburger menu
            floatRight = false;
            this.hamburger = true;
          }

          let getLeastGroupWidth = (data) => {
            return data.length ?? data.reduce((min, x) => x.groupWidth < min ? x.groupWidth : min, data[0].groupWidth);
          }

          this.tabstripWidth.nextMax = this.tabstripWidth.current + getLeastGroupWidth(this.nav.tabGroup.filter(x => !x.showTabs));

          this.tabs.forEach(tab => {
            if (tab.menu && tab.text === tab.menu[0].text) {
              tab.accessLevel = tab.menu[0].accessLevel;
              tab.path = tab.menu[0].path;
              tab.outlet = tab.menu[0].outlet;
              tab.navItemId = tab.menu[0].navItemId;
              tab.params = tab.menu[0].params;
              tab.menu[0].isInVisible = true;
              tab.track = tab.menu[0].track;
              tab.msg = tab.menu[0].msg;
            }
          });
        }

        if (floatRight) {
          this.tabs = this.tabs.reverse();
        }

        resolve(null);
      });
    });
  }


  log(origin: string, level:number, contentJSON: any) {
    this.dataService.setLog(origin, level, contentJSON);
  }

  /**
   * This method creates the element, get the width of the element to
   * update the tab.width property. The setTimeout is necessary to give
   * the DOM time to remove the child element, if we don't the width of
   * the element would be accumulated.
   */
  getTabElementWidth(obj, text, className) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const tabElement = this.renderer.createElement('div');
        this.renderer.appendChild(tabElement, this.renderer.createText(text));
        this.renderer.addClass(tabElement, className);
        this.renderer.appendChild(this.elementRef.nativeElement, tabElement);

        const width = this.elementRef.nativeElement.querySelector('.' + className).offsetWidth;
        this.renderer.removeChild(this.elementRef.nativeElement, tabElement);

        obj.width = width;

        resolve({ obj });
      });
    });
  }

  @HostListener('document:dblclick')
  onDblClick() {
    this.isSlideShow = false;
  }

  slideShow() {
    this.isSlideShow = true;
    const list = [];
    let index = 0;

    const walkThroughTabs = async () => {
      while (this.isSlideShow) {
        this.select(list[index]);
        await this.autoScroll();
        index = index + 1 < list.length ? index + 1 : 0
      }
    }

    for (let tab of this.nav.tabGroup) {
      if (tab.menu && tab.menu.length) {
        for (let menuItem of tab.menu) {
          list.push(menuItem);
        }
      }
    }

    walkThroughTabs();
  }

  autoScroll() {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        let autoScrollY = document.body.scrollHeight - (window.innerHeight + window.scrollY);
        let scrollY = 0;

        const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

        const scroll = async () => {
          while (scrollY < autoScrollY && this.isSlideShow) {
            window.scrollTo({
              top: scrollY,
              left: 0,
              behavior: 'smooth'
            });
            scrollY += 5;
            await delay(25);
          }

          if (!this.isSlideShow) {
            await delay(1000);
          }

          resolve(null);
        };

        scroll();

      }, 3000);

    });
  }

  trackByFn(index, item) {
    return index;
  }
}
