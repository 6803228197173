import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Subscription } from 'rxjs';
import { Nav, NavCurrent, NavItem, Param, WidgetEvent, WidgetState } from '../../widgets';
import { ActivatedRoute, Router} from '@angular/router';
import { CommunicationService } from 'src/app/services/communication.service';
import { GoogleAnalyticsService } from 'src/app/services/google-analytics.service';
import { SEOService } from 'src/app/seo.service';
import { UtilsService } from 'src/app/services/utils.service';
import { ContentService } from 'src/app/services/content.service';
import { CustomFuncService } from 'src/app/services/custom-func.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

type TabType = 'tabs' | 'tabs-dropdown' | 'tabs-dropdown-treeview' | 'tabs-dropdown-fullwidth' | 'tabs-dropdown-submenu' | 'columns' | 'treeview';

@Component({
  selector: 'app-tabstrip2',
  templateUrl: './tabstrip2.component.html',
  styleUrls: ['./tabstrip2.component.scss']
})
export class Tabstrip2Component implements OnInit, OnDestroy, AfterViewInit {
  @Input() widgetId: string;
  @Input() src: string;
  @Input() nav: Nav;
  @Input() component: any;
  @Input() dataItem: any;
  @Input() className: string = '';

  public params: Param[] = [];
  public blocks: any = null;

  public loaded = false;
  public msg: any;
  public isHamburger: boolean = false;
  public infoItem: any = null;
  public currentNavItemId: string | null = null;

  public index: number = 0;

  public dropdownTimeOutHide: any;
  public dropdownTimeOutShow: any;
  public lastDropdown: any;

  public inTabstrip: boolean = false;

  @ViewChild('tabstripContainer') tabstripContainer: ElementRef | undefined;
  @ViewChildren('tabElement') tabElement: QueryList<ElementRef> | undefined;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private modalService: NgbModal,
    private seoService: SEOService,
    public communicationService: CommunicationService,
    private activatedRoute: ActivatedRoute,
    private googleAnalyticsService: GoogleAnalyticsService,
    public dataService: DataService,
    public utilsService: UtilsService,
    public contentService: ContentService,
    public customFuncService: CustomFuncService
  ) {
  }

  routeParamChanged(params) {
    this.select({ navItemId: null });
  }

  ngOnInit(): void {
    if (this.widgetId) {
      this.communicationService.initWidget({
        widgetId: this.widgetId + '_tabstrip',
        component: this,
        state: WidgetState.OK,
        subscribeTo: [
          {
            event: WidgetEvent.QUERYPARAMCHANGED,
            func: 'routeParamChanged'
          },
          {
            event: WidgetEvent.ROUTEPARAMCHANGED,
            func: 'routeParamChanged'
          },
        ]
      });
    }
  }

  ngAfterViewInit() {
    if (!this.nav) {
      this.loadNav().then((data: Nav) => {
        this.nav = data;
        setTimeout(() => {
          this.processNav(this.nav);
        });
      });
    } else {
      setTimeout(() => {
        this.processNav(this.nav);
      });
    }
  }


  loadNav() {
    return new Promise((resolve, reject) => {
      this.dataService.getNav(this.src)
        .then((data: Nav) => {
          resolve(data);
        })
        .catch(error => {
          console.log('error: ' + error)
          reject(error)
        });
    });
  }

  processNav(nav: Nav) {
    !['xfw.product-menu', 'inhaker.product-menu', 'xfw.job-component', 'xfw.component-datagroup'].includes(this.src) ?
      (this.nav = this.utilsService.toTabstrip2(this.contentService.changeLanguage(this.nav, this.communicationService.user.language))) :
      this.nav = structuredClone(this.nav);

    if (['tabs-dropdown', 'tabs-dropdown-treeview', 'tabs-dropdown-fullwidth', 'tabs-dropdown-submenu'].includes(this.nav.type)) {
      this.nav.gridTemplateColumns = this.nav.menu.map((tab:any) =>  tab['gridTemplateColumn'] ?? 'min-content').join(' ');
    }

    const initNav = (item: any, level: number, navItemId: string, index: number | null) => {
      item.navItemId = index !== null ? [navItemId, index.toString()].join('.') : navItemId;
      !item.hasOwnProperty('disabled') && (item.disabled = false);

      item.text && (item.text = this.utilsService.parseTemplate(item.text, this.communicationService.user));

      if (item.isVisible && item.func && item.type !== 'button') {
        item.params.push({
          key: this.widgetId,
          val: item.navItemId,
          isQueryParam: true
        });

        this.communicationService.initQueryParams(item.params, this.widgetId);
      }

      if (item.isSelectedVariableReference) {
        const result = this.utilsService.parseVariableToReference(item.isSelectedVariableReference, this.communicationService.user);
        item.isSelected = result.reference[item.isSelectedVariableReference.split('.').pop()];
      }

      // check of er disabledCondities zijn waar aan voldaan wordt. Alleen op dataItem, zou ook op de queryParams moeten.
      // die kunnen we combineren?
      (item.disabledCond) && (item.disabled = this.utilsService.checkCond(this.dataItem, item.disabledCond));

      if (item.menu) {
        this.nav.type.includes('treeview') && (item.collapsed = level >= this.nav.maxLevel);
        item.menu.forEach((menuItem: any, index: number) => initNav(menuItem, level + 1, item.navItemId, index));
      }
    }

    initNav(this.nav, 0, this.nav.navId.toString(), null);

    // zullen we de disabled navItems verwijderen?

    this.loaded = true;
    this.select({ navItemId: null });

    this.checkSizes();
  }

  getSelected(nav: any, navItemId: string | null) {
    let activatedRoute: any = this.activatedRoute;
    let route = activatedRoute._routerState.snapshot.url.split('?')[0].substring(1);
    let queryParamsObject = activatedRoute.queryParams._value;

    let found: any = null;

    const checkPathAndParams = ((tab, route, queryParamsObject) => {
      tab.isSelected = (!tab.noToggle ? tab.isSelected : false);

      if ((tab.path && tab.path === route) || (tab.func === 'selectDataGroupItem')) {
        const tabParamsObject = tab.params ? this.utilsService.objectFromParams(tab.params.filter(param => param.isQueryParam && param.val)) : {};

        !found && (found = (Object.keys(queryParamsObject).length > 0 && Object.keys(tabParamsObject).length > 0) ?
                  (this.utilsService.isSubset(queryParamsObject, tabParamsObject) ? tab : null) : tab);
      }
      tab.menu && !found && tab.menu.forEach(menuItem => checkPathAndParams(menuItem, route, queryParamsObject))
    });

    checkPathAndParams(nav, route, queryParamsObject);

    found && (found.isSelected = true);
    return found;
  }

  select(item: any, content?: any) {
    if (this.loaded) {
      // de perform alleen uitvoeren als er daadwerkelijk op een knop gedrukt wordt, anders niet.
      let doPerform = !(item.navItemId === null || item.navItemId === undefined);

      if (this.nav.selectable !== 'none') {
        ((item.navItemId === null || item.navItemId === undefined) && this.currentNavItemId === null) &&
        (item = this.getSelected(this.nav, item.navItemId) ?? item);

        const select = (tab: any, navItemId: string | null) => {
          this.nav.type === 'tabs' ? tab.noToggle && (tab.isSelected = navItemId ? navItemId === tab.navItemId : false) :
                                    tab.noToggle && (tab.isSelected = navItemId ? navItemId.startsWith(tab.navItemId) : false);

          // volledige match, dan is het item gevonden
          (navItemId === tab.navItemId) && (item = tab);

          tab.menu && tab.menu.forEach((menuItem: any) => select(menuItem, navItemId));
        }

        // autoSelect tabstrip, selecteer eerste item, als er nog niets is geselecteerd.
        (this.nav.autoSelect && !item.navItemId && this.currentNavItemId === null) && (item = this.nav['menu'].find(menuItem => !menuItem.disabled));

        // als het geen noToggle is, dan select doen.
        item.navItemId && item.noToggle ? select(this.nav, item.navItemId ?? this.currentNavItemId)
                                        : !item.noToggle && (item.isSelected = !item.isSelected);
      }

      // menu collapse / expand
      item.menu && ['treeview', 'tabs-dropdown-treeview'].includes(item.type) &&
        (item.collapsed = !item.collapsed);

      if (item.navItemId && item.navItemId !== this.currentNavItemId && (doPerform || item.func)) {
        this.perform(item, content);
        item.noToggle && (this.currentNavItemId = item.navItemId);
      } else if (!item.navItemId) {
        this.currentNavItemId = null;
      }
    }
  }

  perform(item: NavItem, content?: any) {
    const url: any = [item.path];
    let queryParams = this.communicationService.queryParams;

    // close all tabs
    // als het item een menu heeft, dan wordt showMenu aangeroepen, en wordt de dropdown weer getoond.
    // daar nog een betere oplossing voor vinden.
    this.nav.menu.forEach((tab: any) => tab.dropdown = false);

    this.isHamburger && this.toggleFullscreen(false); // close fullscreen after click on tab

    item.track && this.googleAnalyticsService.sendAnalyticsEvent(item.track);

    if (item.path) {
      if (!item.outlet) {
        item.path.startsWith('http') && window.open(item.path, '_blank');

        let fragment = url[0].split('#')[1];
        url[0]=url[0].split('#')[0];

        url.push({outlets: { window: null } });

        this.communicationService.setQueryParams(this.widgetId, item.params, url, fragment);
      } else {
        const outlets = {};
        outlets[item.outlet] = [item.path].join('/').split('/');

        this.communicationService.mergeQueryParams(this.widgetId, item.params, [{ outlets: outlets }]);
      }
    } else if (item.func) {
      if (item.msg && item.msg?.action !== 0) {
        this.msg = item.msg;
        this.modalService.open(content, { centered: true }).result.then((result) => {
          if (result === 'OK') {
            this.customFuncService.perform(item.func, item.procedure, item.params, item.paramsFrom, {...item.dataItem, ...this.dataItem}, this.component);

            item.type !== 'button' && this.communicationService.mergeQueryParams(this.widgetId, item.params);
          }
        });
      } else {
        this.customFuncService.perform(item.func, item.procedure, item.params, item.paramsFrom, {...item.dataItem, ...this.dataItem}, this.component);


        item.type !== 'button' && this.communicationService.mergeQueryParams(this.widgetId, item.params, [], null, true);
      }
    }
  }

  ngOnDestroy() {
    this.communicationService.destroyWidgets([this.widgetId + '_tabstrip']);
    this.communicationService.destroyQueryParams(this.widgetId);
  }

  showMenu(item, value) {
    if (value) {
      if (this.nav.type === 'tabs-dropdown-fullwidth') {
        this.lastDropdown && (this.lastDropdown.dropdown = false);
        item.dropdown = true;
        this.blocks = null;
        this.lastDropdown = item;
        item.init = true;
      } else {
        item === this.lastDropdown && clearTimeout(this.dropdownTimeOutHide);
        this.dropdownTimeOutShow = setTimeout(() => {
          item.dropdown = true;
          this.blocks = null;
        }, this.nav.delay ?? 0);
      }
    } else {
      if (this.nav.type === 'tabs-dropdown-fullwidth') {
        this.lastDropdown.dropdown = false;
        item && (item.dropdown = false);
      } else {
        clearTimeout(this.dropdownTimeOutShow);
        this.lastDropdown = item;

        this.dropdownTimeOutHide = setTimeout(() => {
          item && (item.dropdown = false);
          this.nav.type === 'tabs-dropdown-fullwidth' && (item.isSelected = false);
        }, (this.nav.delay * 1.5) ?? 0);
      }
    }
  }

  toggleFullscreen(value: boolean) {
    setTimeout(() => {
      if (value && !this.nav.fullscreen) {
        this.nav.fullscreen = true;
        [this.nav.type, this.nav.fullscreenType] = [this.nav.fullscreenType, this.nav.type]; // swap values
        [this.nav.gridTemplateColumns, this.nav.fullscreenGridTemplateColumns] = [this.nav.fullscreenGridTemplateColumns, this.nav.gridTemplateColumns]; // swap values
      } else if (!value && this.nav.fullscreen) {
        this.nav.fullscreen = false;
        [this.nav.type, this.nav.fullscreenType] = [this.nav.fullscreenType, this.nav.type]; // swap values
        [this.nav.gridTemplateColumns, this.nav.fullscreenGridTemplateColumns] = [this.nav.fullscreenGridTemplateColumns, this.nav.gridTemplateColumns]; // swap values
      }
    });
  }

  checkSizes(event?: any) {
    setTimeout(() => {
      const tabstripContainerWidth = this.tabstripContainer?.nativeElement.offsetWidth || 0;

      // de totale scroll breedte voor de tabstripContainer wijkt soms enorm af. Dat kan niet. Dat komt omdat
      // het grid zelf nog aan het renderen is. De breedte wijkt dan zeker 100px af van de vorige breedte.
      // dat laten we niet toe.

      const inRange = (x, y, range) => { return Math.abs(x - y) <= range; }

      const scrollWidth = this.tabstripContainer?.nativeElement.children[0].scrollWidth;

      (!(inRange(scrollWidth, tabstripContainerWidth, 10) && this.isHamburger) &&
      (!(scrollWidth && !inRange(scrollWidth, tabstripContainerWidth, 100)) || !this.nav.width)) &&
        (this.nav.width = scrollWidth);

      (this.tabElement?.toArray().length && this.nav.type === 'tabs-dropdown-fullwidth') &&
        this.tabElement.toArray().forEach((tabElement: ElementRef, index: number) => {
          this.nav.menu[index].left = -tabElement.nativeElement.offsetLeft;
          this.nav.menu[index].width = tabstripContainerWidth - 2;
        });

      if ((((this.nav.width > tabstripContainerWidth) && !this.isHamburger) && !['tabs'].includes(this.nav.type)) || ['xxs','xs'].includes(event?.classNameWidth) ) {
        setTimeout(() => {
          this.isHamburger = true;
        });
      } else if (this.isHamburger && (this.nav.width <= tabstripContainerWidth)) {
        this.isHamburger = false;
      }
    });
  }

  getBlocks(item: any) {
    return;

    // this.utilsService.paramsChanged(this.params, item.params) && item.path &&
    //   this.dataService
    //   .getBlocks(item.path, this.communicationService.user.language, item?.paramJSON)
    //   .then(
    //     (data: { blocks: any[]; environmentJSON: any; mlNavJSON: any }) => {

    //       this.params = structuredClone(item.params);

    //       this.blocks = data.blocks;
    //   });
  }

  trackByFn(index, item): number {
    (index === 0) && (this.index = 0);

    return this.index++;
  }
}
