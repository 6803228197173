import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable, lastValueFrom, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataGroup, DataTable, Edit, FieldGroup, Fields } from '../data';
import { NetworkService } from './network.service';
import { Nav, Param, TreeData, WidgetEvent } from '../widgets';
import { UtilsService } from './utils.service';
import { ContentService } from './content.service';
import { CustomFuncService } from './custom-func.service';
import { CommunicationService } from './communication.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  data: any[];
  originalData: any[];

  public counter:number = 0;

  private _dataUrl = '/assets/data/offProductNas.json';
  private _dataTableUrl = 'assets/data/offProductNas_descr.json';
  private _timeLineUrl = 'assets/data/timeLine.json';
  private _timeLineTableUrl = 'assets/data/timeLineTable.json';
  private _configuratorUrl = '/assets/data/configurator.json';

  public blockLayoutGridData: any = {};
  public searchNav: Nav;

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private contentService: ContentService,
    private customFuncService: CustomFuncService,
    private communicationService: CommunicationService,
    private utilsService: UtilsService) { }

  getBlocks(path: string, language, paramJSON?: Param[]) {
    return new Promise((resolve, reject) => {
      const subscription = this.getDataTable({
        db: 'xfw3',
        src: [{ name: 'getBlocks', type: 'data'}],
        widgetId: 'getBlocks',
        paramsFrom: [
          {
            key:'path'
          },
          {
            key: 'searchTxt'
          }
        ],
        titleFrom: '',
        collapsed: false,
        dataTable: undefined,
        layout: undefined,
        noHeader: false,
        noTitle: false,
        params: [],
      },
      'xfw3',
      'getBlocks',
      [
        {
          key: 'path',
          val: path
        },
        {
          key: 'searchTxt',
          val: paramJSON ? (paramJSON.some(param => param.key === 'searchTxt') ? paramJSON.find(param => param.key === 'searchTxt').val : '') : ''
        }
      ]).subscribe(dataTable => {
        resolve(this.combineGetBlocks(dataTable, language));
        subscription.unsubscribe();
      });
    });
  }

  combineGetBlocks(dataTable: DataTable[], language: string): any {
    const blocks: any[] = [];
    const environmentJSON: any = dataTable[0].data[0].environmentJSON;
    const mlNavJSON: any = dataTable[0].data[0].mlNavJSON;
    const dataGroups: any[] = dataTable[0].data[0].dataGroups;
    const navs: any[] = dataTable[0].data[0].navs;

    const addDatagroupsAndNavs = (blockId, block, dataGroups, navs) => {
      if (block.cols && block.cols[0].col) {
        block.cols.forEach(cols => {
          cols.col.forEach(col => {
            if (col.hasOwnProperty('paramJSON')) {
              col.paramJSON.widgetId = col.paramJSON.widgetId || col.paramJSON.dataGroup;

              col.paramJSON.dataGroupJSON = dataGroups.find(row => row.dataGroup === col.paramJSON.dataGroup + '_' + blockId).dataGroupJSON;

              // hier worden de vaste waarden uit de blocks in de dataGroup.params gezet. Er wordt niet gekeken naar paramsFrom.

              col.paramJSON.dataGroupJSON.forEach(dataGroupItem => {
                if (col.paramJSON?.params) {
                  dataGroupItem.params.forEach(param => {
                    if (col.paramJSON.params.some(blockParam => blockParam.key === param.key)) {
                      param.val = col.paramJSON.params.find(blockParam => blockParam.key === param.key).val;
                    }
                  });
                }

                const convertToSrcArray = (dataGroupItem: DataGroup) => {
                  dataGroupItem.children.length &&
                    dataGroupItem.children.forEach(child => {
                      convertToSrcArray(child);
                    });


                  (!Array.isArray(dataGroupItem.src)) && (dataGroupItem.src = [{ name: dataGroupItem.src, type: 'data' }]);

                };

                convertToSrcArray(dataGroupItem);

              });
            }
            col.hasOwnProperty('navJSON') && (col.navJSON.navDataJSON = navs.find(row => row.nav === col.navJSON.nav).navJSON[0]);
          });
        });
      }

      return block;
    }

    dataTable[0].data.forEach(block => {
      let contentJSON = this.contentService.changeLanguage(block.contentJSON, language);
      if(Array.isArray(contentJSON)) {
        contentJSON.forEach(contentBlock => {
          blocks.push({
            blockId: block.blockId,
            blocksId: block.blocksId,
            sortOrder: block.sortOrder,
            contentJSON: addDatagroupsAndNavs(block.blockId, contentBlock,dataGroups,navs)
          });
        })
      } else {
        blocks.push({
          blockId: block.blockId,
          blocksId: block.blocksId,
          sortOrder: block.sortOrder,
          contentJSON: addDatagroupsAndNavs(block.blockId, contentJSON,dataGroups,navs)
        });
      }
    });

    return {
      blocks: blocks,
      environmentJSON: environmentJSON,
      mlNavJSON: mlNavJSON,
    }
  }

  getTreeData(data, primaryKey: string, parentKey: string) {
    const dataToTreeData = (treeData, parent = 0) =>
      treeData.filter(row => row[parentKey] === parent)
      .map(child => ({ ...child, children: dataToTreeData(treeData, child[primaryKey]) }));

     return dataToTreeData(data);
  }


  /**
   * Save data creates the Mutations list.
   *
   * new 'Inserts' need a negative id. Check if all crud inserts have a
   * negative id.
   */
  saveData() {

  }

  getLog() {
    return this.networkService.getLog();
  }

  setLog(origin, level, contentJSON) {
    this.networkService.setLog(origin, level, contentJSON);
  }

  setInitialParams(queryParams: Param[], userParams: Param[], dataGroup: DataGroup[]) {
    dataGroup.forEach(dataGroupItem => {
      dataGroupItem.children && this.setInitialParams(queryParams, userParams, dataGroupItem.children);

      !dataGroupItem.selected && (dataGroupItem.selected = false); // zodat de property altijd bestaat

      this.communicationService.initQueryParams(dataGroupItem.params, dataGroupItem.widgetId);

      const edit: Edit = {
        type: 'unset',
        mode: 'view',
        updateMutations: 'save',
        accessLevel: {
          crud: ['moderator']
        },
        create: false,
        update: false,
        delete: false
      };

      const localStorageParamKeys = this.communicationService.localStorageParams.map(param => param.key);

      dataGroupItem.params.filter(param => param.isLocalStorageParam).forEach(param => {
        localStorageParamKeys.includes(param.key) ?
          param.val = this.communicationService.localStorageParams.find(localStorageParam => localStorageParam.key === param.key).val
          :
          this.communicationService.localStorageParams = [param];
      });

      dataGroupItem.oldParams = structuredClone(dataGroupItem.params);

      dataGroupItem.scroll = Math.max(0, dataGroupItem.page);

      (!dataGroupItem.edit) ?
        dataGroupItem.edit = edit
        :
        dataGroupItem.edit = {...edit, ...dataGroupItem.edit};
    });
  }

  setParams(queryParams: Param[], userParams: Param[], data: any[], dataGroup: DataGroup[], initial:boolean = false) {
    dataGroup.forEach(dataGroupItem => {
      dataGroupItem.children && this.setParams(queryParams, userParams, data, dataGroupItem.children);

      // update localStorageParams, checken of het allemaal werkt. Testcode gebruiken.
      this.communicationService.localStorageParams?.length && this.communicationService.localStorageParams.forEach(localStorageParam => {
        dataGroupItem.params.filter(param => param.isLocalStorageParam).forEach(param => {
          Object.keys(localStorageParam).forEach(key => {
            param.key === key && (param[key] = localStorageParam[key]);
          });
        });
      });

      dataGroupItem.paramsFrom.forEach(paramFrom => {
        if (paramFrom.src === 'user') {
          if (userParams.some(userParam => userParam.key === paramFrom.key)) {
            let val = userParams.find(userParam => userParam.key === paramFrom.key).val;
            dataGroupItem.params.find(param => param.key === paramFrom.key || param.alias === paramFrom.key).val = isNaN(val) ? val : Number(val);
          }
        }

        if (paramFrom.src === 'userConfig') {
          if (userParams.some(userParam => userParam.key === 'config')) {
            const userConfigParams = this.utilsService.paramsFromObject(userParams.find(param => param.key === 'config').val);
            let val = userConfigParams.find(userParam => userParam.key === paramFrom.key)?.val;

            dataGroupItem.params.find(param => param.key === paramFrom.key || param.alias === paramFrom.key).val = isNaN(val) ? val : Number(val);
          }
        }

        if (paramFrom.src === 'sessionConfig') {
          let sessionConfigParams = [];
          this.communicationService.sessionConfig &&
            (sessionConfigParams = this.utilsService.paramsFromObject(this.communicationService.sessionConfig));

          let val = sessionConfigParams.find(sessionParam => sessionParam.key === paramFrom.key)?.val;
          val && (dataGroupItem.params.find(param => param.key === paramFrom.key || param.alias === paramFrom.key).val = isNaN(val) ? val : Number(val));

        }

        // gebruik queryParams om de params te vullen. Let op, staat de param niet in de paramsFrom, dan wordt de param ook niet aangepast.

        if ((!paramFrom.src || paramFrom.prefereQueryParam) && queryParams) {
          if (queryParams.some(queryParam => queryParam.key === paramFrom.key)) {
            let val = queryParams.find(queryParam => queryParam.key === paramFrom.key).val;
            dataGroupItem.params.find(param => param.key === paramFrom.key || param.alias === paramFrom.key).val = isNaN(val) ? val : Number(val);
          } else if (dataGroupItem.params.some(param => (param.key === paramFrom.key || param.alias === paramFrom.key) && param.isQueryParam && !initial)) {
            dataGroupItem.params.find(param => (param.key === paramFrom.key || param.alias === paramFrom.key) && param.isQueryParam && !initial).val = null;
          }
        }
      });

      // console.log('eerste stap setParams',
      //   structuredClone(dataGroupItem.params),
      //   structuredClone(dataGroupItem.oldParams),
      //   this.utilsService.paramsChanged(dataGroupItem.params, dataGroupItem.oldParams),
      //   initial,
      //   data);

      dataGroupItem.paramsSet = this.utilsService.paramsChanged(dataGroupItem.params, dataGroupItem.oldParams);

      // console.log('step 1', dataGroupItem.paramsSet);

      if (initial || !data || !data.length) {
        dataGroupItem.paramsSet = dataGroupItem.params.filter(param => !param.isOptional && !param.isLocalStorageParam).filter(param => param.val === null || param.val === undefined).length ? false : true;

      } else if (dataGroupItem.paramsSet && data.length && dataGroupItem.params.filter(param => param.val !== null && param.val !== undefined).length === dataGroupItem.oldParams.filter(param => param.val !== null && param.val !== undefined).length) {
        dataGroupItem.paramsSet = false;

        // console.log('step 2', structuredClone(data), structuredClone(dataGroupItem.params.filter(param => !param.isOptional && (param.val !== null && param.val !== undefined))));

        dataGroupItem.params.filter(param => !param.isOptional && (param.val !== null && param.val !== undefined)).forEach(param => {
          (!data.some(item => item[param.key]?.toString() === param.val?.toString())) &&  (dataGroupItem.paramsSet = true);
        });
      }

      if (dataGroupItem.paramsSet && dataGroupItem.dataTable && dataGroupItem.dataTable[0]?.data?.length) {
        const findParams: Param[] = [];

        [...dataGroupItem.dataTable[0].primaryKeys, ...dataGroupItem.dataTable[0].foreignKeys || []].forEach(field => {
          const param = dataGroupItem.params.find(param => param.key === field.key);
          (param) &&
            findParams.push(
              {
                key: param.key,
                val: param.val
              }
            );
        });

        // console.log('step 3', findParams);

        findParams.length && (dataGroupItem.paramsSet = !dataGroupItem.dataTable[0].data.some(item => this.utilsService.isSubset(item, this.utilsService.objectFromParams(findParams))));
      }

    });
  }

  loadDataGroup(src:string, dataGroup: DataGroup[]) {
    return new Promise((resolve, reject) => {
      dataGroup = [];

      setTimeout(() => {
        const subscription = this.networkService.query(
          [
            {
              db: 'xfw3',
              src: 'getDataGroup',
              params: [{ key: 'dataGroup', val: src }]
            }
          ], 'json').subscribe(data => {

            if (data.length && data[0].data[0].dataGroupJSON) {
              dataGroup = JSON.parse(data[0].data[0].dataGroupJSON);

              const convertToSrcArray = (dataGroupItem: DataGroup) => {
                if (dataGroupItem.children.length) {
                  dataGroupItem.children.forEach(child => {
                    convertToSrcArray(child);
                  });
                }

                if (!Array.isArray(dataGroupItem.src)) {
                  dataGroupItem.src = [{ name: dataGroupItem.src, type: 'data' }];
                }
              };

              dataGroup.forEach(dataGroupItem => {
                convertToSrcArray(dataGroupItem);
              });
            } else {
              console.log('dataGroup not found:', data, src);
            }

            resolve(dataGroup);
          });
      });

    });
  }

  getDataTable(dataGroupItem: DataGroup, db, src: string, params: Param[]): Observable<DataTable[]> {
    if (src.indexOf('local') >= 0) {
      return of(dataGroupItem.dataTable);
    } else {
      let dataTable: DataTable[];

      const queryParams = {
        db: db,
        src: src,
        params: [],
      };

      queryParams.params = structuredClone(dataGroupItem.params);

      // we moeten niet blijven kloten met die queryParams. Ik denk dat alles ook goed blijft gaan als we
      // dit helemaal weg doen. Dat moet ik even met iemand testen.

      dataGroupItem.paramsFrom.forEach(paramFrom => {
        !paramFrom.src && ((!queryParams.params.some(param => param.key === paramFrom.key || param.alias === paramFrom.key)) ?
            queryParams.params.push({
              key: params.find(param => param.alias === paramFrom.key || param.key === paramFrom.key).key,
              val: params.find(param => param.alias === paramFrom.key || param.key === paramFrom.key).val
            }) :
            queryParams.params.find(param => param.alias === paramFrom.key || param.key === paramFrom.key).val =
              params?.find(param => param.alias === paramFrom.key || param.key === paramFrom.key)?.val ||
              queryParams.params.find(param => param.alias === paramFrom.key || param.key === paramFrom.key).val);
      });

      const title = dataGroupItem.titleFrom ?
        params.some(x => x.key === dataGroupItem.titleFrom) ?
          params.find(x => x.key === dataGroupItem.titleFrom).val : null : null;

      // console.log('loadData:',dataGroupItem.widgetId, JSON.stringify(dataGroupItem.paramsFrom), JSON.stringify(queryParams));

      return this.networkService.query( [queryParams], 'json').pipe(
        map((data) => {

          let metaDataTable: DataTable[];

          if (Array.isArray(data[0])) {
            dataTable = [data[0][0]];
            metaDataTable = [data[0][1]];
          } else {
            dataTable = [data[0]];
          }

          dataTable[0].queryParams = this.utilsService.paramsAlias({
            params: this.utilsService.mergeParams({
              params: dataGroupItem.params,
              paramsToMerge: dataTable[0].queryParams,
              append: true,
              checkQueryParam: false,
              deepCopy: true
            }),
            paramsAlias: queryParams.params
          });

          dataTable[0].originalData = structuredClone(dataTable[0].data);
          dataTable[0]['selected'] = { rowIndex: null, cell: null };

          dataGroupItem.dataTable = dataTable;

          this.updateCardDataGroupItem(dataGroupItem);

          metaDataTable && this.processMetaData(dataGroupItem, metaDataTable);

          this.dataAgg(dataTable);

          return dataTable;
        })
      );
    }
  }

  processMetaData(dataGroupItem: DataGroup, metaDataTable: DataTable[]) {
    dataGroupItem.edit.create = metaDataTable[0].data[0].createNas;

    const fieldsMerge = metaDataTable[0].data[0].fieldsMerge ? JSON.parse(metaDataTable[0].data[0].fieldsMerge) : null;
    const formGroupValidation = metaDataTable[0].data[0].formGroupValidation ? JSON.parse(metaDataTable[0].data[0].formGroupValidation) : null;
    const agg = metaDataTable[0].data[0].agg ? JSON.parse(metaDataTable[0].data[0].agg) : null;

    fieldsMerge &&
      dataGroupItem.dataTable[0].fields.forEach((field, fieldIndex) => {
        field.fieldGroup.forEach(fieldGroup => {
          if (fieldsMerge.fields[fieldIndex].fieldGroup.some(field => field.key === fieldGroup.key)) {
            fieldGroup = this.utilsService.deepMerge(fieldGroup, fieldsMerge.fields[fieldIndex].fieldGroup.find(field => field.key === fieldGroup.key))
          }
        });
      });

    formGroupValidation && (dataGroupItem.formGroupValidation.data = formGroupValidation);
    agg && (dataGroupItem.dataTable[0].agg = agg);
  }

  updateCardDataGroupItem(dataGroupItem: DataGroup) {
    if (dataGroupItem.card?.body?.fields && dataGroupItem.dataTable[0]?.fields) {
      dataGroupItem.card.body.fields.forEach(field => {
        dataGroupItem.dataTable[0].fields[0].fieldGroup.forEach(fieldGroup => {
          if (fieldGroup.key === field.field) {
            field.label = fieldGroup.templateOptions.label.text;
          }
        });
      });

      dataGroupItem.card.body.fields.slice().reverse().forEach(field => {
        dataGroupItem.dataTable[0].fields[0].fieldGroup.unshift(...dataGroupItem.dataTable[0].fields[0].fieldGroup.splice(dataGroupItem.dataTable[0].fields[0].fieldGroup.findIndex(fieldGroup => fieldGroup.key === field.field),1));
      });

      dataGroupItem.dataTable[0].fields[0].fieldGroup.forEach(fieldGroup => {
        !dataGroupItem.card.body.fields.map(field => field.field).includes(fieldGroup.key) && (fieldGroup.type = 'hidden');
      });
    }
  }

  dataAgg(dataTable: DataTable[]) {
    if (dataTable[0].agg && !dataTable[0].agg.meta ) {
      const data: any[] = dataTable[0].data.some(row => row.checked) ? dataTable[0].data.filter(row => row.checked) : dataTable[0].data;

      dataTable[0].agg.fields.forEach(aggItem => aggItem.result = 0);

      data.forEach(item => {
        dataTable[0].agg.fields.forEach(aggItem => {
          switch(aggItem.func) {
            case 'sum':
              aggItem.result = aggItem.result ? aggItem.result + item[aggItem.key] || 0 : item[aggItem.key] || 0;
              break
            case 'count':
              aggItem.result = aggItem.result ? aggItem.result + (item[aggItem.key] ? 1 : 0) : (item[aggItem.key] ? 1 : 0);
              break;
          }
        });
      });
    }
  }

  initLookupData(lookupData, dataTable: DataTable[], selected: number) {
    const createLookupData = (lookupData, fieldGroup) => {
      const params: Param[] = [];

      if (fieldGroup.type === 'comboBox') {
        if (!fieldGroup.cascadeFrom) {
          fieldGroup.cascadeFrom = [];
        }
        if (!fieldGroup.cascadeFrom.some(x => x.key === 'searchTxt')) {
          fieldGroup.cascadeFrom.push({key: 'searchTxt'});
        }
      }

      if (fieldGroup.cascadeFrom) {
        fieldGroup.cascadeFrom.forEach(param => {
          params.push({
            key: param.key,
            val: dataTable[0].data[selected] ? dataTable[0].data[selected][param.key] : null
          });
        });
      }

      if (fieldGroup.xfw.inputData.src) {
        if (!lookupData.some(x => x.src === fieldGroup.xfw.inputData.src)) {
          lookupData.push({
            db: fieldGroup.xfw.inputData.db ?? 'xfw3',
            src: fieldGroup.xfw.inputData.src,
            params: [...params, ...fieldGroup.xfw.inputData.params ?? []],
            cascadeFrom: fieldGroup.cascadeFrom,
            type: fieldGroup.type,
            refresh: true
          });

        } else {
          lookupData.find(x => x.src === fieldGroup.xfw.inputData.src).params = [...params, ...fieldGroup.xfw.inputData.params ?? []];
          lookupData.find(x => x.src === fieldGroup.xfw.inputData.src).refresh = true;
        }
      } else if (fieldGroup.xfw.inputData.func) {
        if (!lookupData.some(x => x.func === fieldGroup.xfw.inputData.func)) {
          lookupData.push({
            func: fieldGroup.xfw.inputData.func,
            params,
            cascadeFrom: fieldGroup.cascadeFrom,
            refresh: true
          });

        } else {
          lookupData.find(x => x.func === fieldGroup.xfw.inputData.func).params = params;
          lookupData.find(x => x.func === fieldGroup.xfw.inputData.func).refresh = true;
        }
      }
    };

    if (dataTable[0]?.formLookup?.xfw?.inputData) {
      createLookupData(lookupData, dataTable[0].formLookup);
    }

    dataTable[0].fields.forEach(group => {
      group.fieldGroup.filter(fieldGroup => fieldGroup.xfw.inputData?.src || fieldGroup.xfw.inputData?.func).forEach(fieldGroup => {
        createLookupData(lookupData, fieldGroup);
      });
    });

    return lookupData;
  }

  /**
   * getLookupData only refreshes the tables who need to be refreshed.
   * That's why we filter the lookupData.
   *
   * @param lookupData -> all the lookupData
   */

  getLookupData(lookupData) {
    return new Promise((resolve, reject) => {
      const lookupTables = lookupData.filter(x => x.refresh && x.src && !x.src.startsWith('local.'));
      const lookupFunc = lookupData.filter(x => x.refresh && x.func);

      this.networkService.query(lookupTables, 'json').subscribe(dataTables => {
        dataTables.forEach((dataTable, index) => {
          this.processData({
            data: dataTable.data,
            fields: dataTable.fields,
            parse: true
          });

          lookupTables[index].data = dataTable.data;
          lookupTables[index].refresh = false;
        });

        if (lookupFunc.length) {
          lookupFunc.forEach(func => {
            this.customFuncService.performWithPromise(func.func, null, func.params, func.paramsFrom).then(data => {
              func.data = data;

              resolve(lookupData);
            });
          });
        } else {
          resolve(lookupData);
        }
      });
    });
  }

  processData({data, fields, parse}: {data: any[], fields: Fields[], parse: boolean}): void {
    for(let item of data) {
      for(let field of fields) {
        for(let fieldGroup of field.fieldGroup) {
          if (fieldGroup.xfw.isJSON) {
            if (parse && typeof item[fieldGroup.key] === 'string') {
              item[fieldGroup.key] = JSON.parse(item[fieldGroup.key]);
            } else if (!parse && typeof item[fieldGroup.key] === 'object' && item[fieldGroup.key] !== null) {
              item[fieldGroup.key] = item[fieldGroup.key] // JSON.stringify(item[fieldGroup.key]);
            }
          }
        }
      }
    }
  }

  searchDataTable(dataGroupItem: DataGroup, searchFields: string[], searchTxts: string[]) {
    if (!(searchTxts.length === 1 && searchTxts[0] === '')) {
      dataGroupItem.search.searchResults = [];

      dataGroupItem.dataTable[0].data.forEach((row,index) => {
        let checkTxt = '';
        let checkNr = 0;

        searchFields.forEach((searchField) => {
          checkTxt += row[searchField] ? row[searchField].toLocaleLowerCase() : '';
        });

        searchTxts.forEach((searchTxt) => {
          if (checkTxt.includes(searchTxt.toLowerCase())) {
            checkNr++;
          }
        });

        if (checkNr === searchTxts.length) {
          dataGroupItem.search.searchResults.push(index);
          row.highLight = true;
        }
      });
    } else {
      dataGroupItem.dataTable[0].data.forEach(row => row.highLight = false);
    }
  }

  setSortField(dataTable: DataTable[], sortFields: string[], fields: Fields[], fieldGroup: FieldGroup) {
    switch (fieldGroup.xfw.sort.direction) {
      case 0:
        fieldGroup.xfw.sort.direction = 1;
        sortFields.push(fieldGroup.key);
        fieldGroup.xfw.sort.order = sortFields.length;
        break;
      case 1:
        fieldGroup.xfw.sort.direction = -1;
        sortFields[fieldGroup.xfw.sort.order - 1] = '-' + fieldGroup.key;
        break;
      case -1:
        fieldGroup.xfw.sort.direction = 0;
        sortFields.splice(fieldGroup.xfw.sort.order - 1, 1);
        fieldGroup.xfw.sort.order = 0;

        // tslint:disable-next-line:prefer-for-of
        for (let z = 0; z < fields.length; z++) {
          for (const fGroup of fields[z].fieldGroup) {
            fGroup.xfw.sort.order = 0;

            for (let i = 0; i < sortFields.length; i++) {
              if (fGroup.key === sortFields[i] || fGroup.key === sortFields[i].substring(1)) {
                fGroup.xfw.sort.order = i + 1;
              }
            }
          }
        }
        break;
    }

    if (sortFields.length) {
      const fieldSorter = (sortfields) => (a, b) => sortfields.map(o => {
        let dir = 1;
        if (o[0] === '-') {
          dir = -1; o = o.substring(1);
        }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
      }).reduce((p, n) => p ? p : n, 0);

      let SortedResult = dataTable[0].data.sort(fieldSorter(sortFields));
    } else {
      // reset to original sort order.
      dataTable[0].data = dataTable[0].data.sort((a, b) => a.trackBy - b.trackBy);
    }
  }

  getData(): Observable<any[]> {
    return this.http.get<any>(this._dataUrl).pipe(
      map((data) => {
          data.forEach(row => {
            row.crud = null;
            row.check = null;
          });

          return data;
      })
    );
  }

  async getNav(nav): Promise<Nav> {
    const data = await lastValueFrom(this.networkService.query( [{db: 'xfw3', src: 'getNav', params: [{ key: 'nav', val: nav }]}],  'json'));

    // Dit is absoluut nodig soms om dingen te laten werken. Soms zit er een extra array om heem. Niet verwijderen aub.
    // Dit is absoluut nodig soms om dingen te laten werken. Soms zit er een extra array om heem. Niet verwijderen aub.
    // Dit is absoluut nodig soms om dingen te laten werken. Soms zit er een extra array om heem. Niet verwijderen aub.
    const navData = data[0].data || data[0][0].data;

    navData.forEach(row => {
      row.navJSON = row.navJSON[0].nav;
    });

    if (navData.length > 1) {
      navData[0].navJSON = { tabGroup: [] };

      for (let index = 1; index < navData.length; index++) {
        navData[0].navJSON.tabGroup = [...navData[0].navJSON.tabGroup, ...navData[index].navJSON.tabGroup];
      }
    }

    return navData.length ? { ...navData[0].navJSON, ...{ type: navData[0].type}} : null;

  }

  /** Hier mag bij de laatste rij maar 1 item geselecteerd worden en bij de rijen daarboven mogen meerdere items geselecteerd worden.
   *  Dus trackBy moet < zijn dan de trackBy van de laatste geselecteerde rij en de foreignKeys moeten ongelijk zijn aan de laatste rij.
   */

  itemCheckboxChange(event: MouseEvent, trackBy, item, dataGroupItem: DataGroup) {
    if (!dataGroupItem.rowOptions.checkable) {
      event.preventDefault();
      return;
    }

    let selectionMode: string;
    const dataTable = dataGroupItem.dataTable;

    if (event.shiftKey && dataGroupItem.rowOptions.checkMultiple) {
      const firstItemChecked = dataTable[0].data.findIndex(row => row.checked);

      trackBy--;

      if (firstItemChecked <= trackBy) {
        for(let i = firstItemChecked; i <= trackBy; i++) {
          dataTable[0].data[i].checked = true;
        }
      } else if (firstItemChecked > trackBy) {
        for(let i = firstItemChecked; i >= trackBy; i--) {
          dataTable[0].data[i].checked = true;
        }
      } else {
        dataTable[0].data[trackBy].checked = true;
      }
    } else if (event.ctrlKey && dataGroupItem.rowOptions.checkMultiple) {
      item.checked = !item.checked;
    } else if (!item.checked) {
      dataTable[0].data.forEach(row => row.checked = false);
      item.checked = true;
    } else {
      dataTable[0].data.forEach(row => row.checked = false);
    }

    if (dataGroupItem.rowOptions.checkSave) {
      dataTable[0].data.forEach(row => row.crud = 'update');
      this.communicationService.performAction({
        info: 'check ' + dataGroupItem.edit.type,
        widgetGroup: [dataGroupItem.widgetId.split('-')[0]],
        event: WidgetEvent.SAVE
      });
    }

    this.dataAgg(dataTable);
  }

  itemCheckboxInit(dataGroupItem: DataGroup) {
    if (dataGroupItem.params.some(param => param.key === [dataGroupItem.widgetId, 'check'].join('_') && param.val)) {
      dataGroupItem.params.find(param => param.key === [dataGroupItem.widgetId, 'check'].join('_')).val.toString().split(',').forEach(trackBy => {
        dataGroupItem.dataTable[0].data.some(row => row.trackBy === Number(trackBy)) &&
          (dataGroupItem.dataTable[0].data.find(row => row.trackBy === Number(trackBy)).checked = true);
      });
    }
  }
}
